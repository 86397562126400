import Vue from 'vue';
import { defaultTo, isNil } from 'lodash';
import { ConfigurationService } from '@nsftx/games-config';
import BarcodeScanPlugin from '@nsftx/seven-gravity-gateway/plugin-barcode-scan';
import { queryStringParser, busService, sentry } from '@/utility';
import translations from './i18n';
import '@/utility/filters';
import App from './App';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

(async () => {
  const queryParams = queryStringParser.parse(window.location.search) || {};
  console.log(' queryParams ', queryParams);

  const configService = new ConfigurationService({
    environment: process.env.VUE_APP_ENVIRONMENT,
    applicationName: defaultTo(queryParams.application, 'Shop'),
    requiredPaths: [
      'productId',
      'tenantId',
      'locale',
    ],
    messageProtocol: 'gravityGateway',
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          isDebug: true,
          slaveId: queryParams.productDisplayId || '',
          gameId: queryParams.productDisplayId || '',
          eventPropagation: {
            click: true,
            keydown: '*',
          },
          eventListeners: {
            keydown: ['9', '13', '49', '52', '54', '56', '97', '100', '102', '106', '107', '111', '187', '191'],
          },
          plugins: [new BarcodeScanPlugin()],
        },
      },
    ],
  });

  try {
    // Append config
    const config = await configService.getConfiguration();
    store.state.config = config;
    store.state.offer = config.state.offer;
    store.dispatch('getEventsOffer', config.state.eventsOffer);
    store.state.gameState = config.state.gameState;
    await translations(store.getters.config.locale).then(({ default: localTranslations }) => {
      store.dispatch('setTranslations', localTranslations);
      busService.init();
    });
    const rules = {
      maxBetNumber: { name: 'maxBetNumber', value: defaultTo(config.rules.maxBetNumber.value, 3) },
      maxBetAmount: { name: 'maxBetAmount', value: defaultTo(config.rules.maxBetAmount.value, 100) },
      minBetAmount: { name: 'minBetAmount', value: defaultTo(config.rules.minBetAmount.value, 1) },
    };
    store.dispatch('setRules', rules);
    store.dispatch('betslip/setTaxes', config.rules.taxes);
    store.dispatch('betslip/updateStake', rules.minBetAmount.value); // set min payin from config

    if (config.user && !isNil(config.user.token)) {
      store.dispatch('setUser', config.user);
    }
    console.log(' --> Config', config);
  } catch (e) {
    console.log(' -->  Config Error ', e);
  }
  Vue.prototype.$config = configService;

  if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
    sentry.start(Vue);
  }

  new Vue({
    router,
    store,
    created() {
      store.dispatch('handleGameState', store.getters.gameState);
    },
    render: (h) => h(App),
  }).$mount('#app');
})();
